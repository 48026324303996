.paginator {
  list-style-type: none;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  user-select: none;

  > li {
    background: $beige;
    border: 0;
    width: 30px;
    text-align: center;
    color: $shaded-gray;
    font-size: 0.8rem;
    padding: 5px;
    margin: 0 5px;
    cursor: pointer;

    &.break {
      cursor: default;
    }
    
    &.active {
      background: $gold;
      color: $white;
    }
  }
}