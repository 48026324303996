@import '../variables';

.death-notice {
  .notice-banner {
    background: url('../assets/images/kreuz.jpg') no-repeat;
    background-size: cover;
    color: #fff;

    h1 {
      color: #fff;
      text-shadow: 0 0 10px rgba(0,0,0,1);
    }

    h2 {
      color: #fff;
      font-weight: 700;
      margin-bottom: 0.5rem;
      text-transform: uppercase;
      font-size: 3.5rem;
    }

    a {
      color: #fff;
      font-size: 0.9rem;
      font-style: italic;
      text-decoration: none;
    }

  }
  .detail {

    .inner-notice {
      padding: 20px;
      border: 1px solid $default-gray;
    }

    h1 {
      margin: 0;
      font-weight: 700;
      text-transform: uppercase;
    }

    img {
      margin: 0 auto;
      display: block;
      width: 100%;
    }

  }

  .details {
    .dn-container {
      min-height: 380px;

      img {
        cursor: pointer;
      }
    }
  }
  .condolences {

    h2 {
      text-align: center;
      text-transform: uppercase;
      font-weight: 600;
    }

    .condolence {
      background: $beige;

      h2 {
        text-align: left;
      }

      em {
        color: initial;
      }
    }
  }

  .sidebar {
    color: $white;
    font-size: 0.9rem;

    aside {
      background: $dark-bluegray;

      .sidebar-item {
        border-bottom: 1px solid rgba($beige, 0.1);

        h2, h3, h4 {
          color: $white;
          font-size: 1.8rem;
        }

      }

    }
  }

  .uk-button {
    white-space: nowrap;
  }

  .notice-text {
    width: 100%;

    .noheight {
      min-height: 0;
      max-height: 0;
      overflow: hidden;
      padding: 0;
      background: none;
    }

    .autoheight {
      min-height: auto;
      max-height: inherit;
      overflow: visible;
      padding: 30px 40px;
      background: #eee;
    }

  }

}

.prevNextContainer {
  top: 50%;
  position: fixed;
  text-align: center;
  width: 100%;

  #nextButton {
    float: right;
    height: 64px;
    width: 128px;
    margin-right: -64px;
    border-radius: 200px 200px 0 0;
    z-index: 10;
    transform-origin: bottom center;
    transform: rotate(270deg);
    font-size: 64px;
  }

  #prevButton {
    float: left;
    height: 64px;
    width: 128px;
    margin-left: -64px;
    border-radius: 200px 200px 0 0;
    z-index: 10;
    transform-origin: bottom center;
    transform: rotate(90deg);
    font-size: 64px;
  }

  @media screen and (max-width: 992px) {
    #nextButton {
      height: 32px;
      width: 64px;
      margin-right: -42px;
      font-size: 32px;
      inline-size: min-content;
    }
    #prevButton {
      height: 32px;
      width: 64px;
      margin-left: -42px;
      font-size: 32px;
      inline-size: min-content;
    }
  }

}

.death-notice-overview {

  .banner {
    background: url('../assets/images/angel.jpg') no-repeat;
    background-size: cover;
  }
}
