.business-directory {
  .banner {
    background-image: url('../assets/images/business-directory.jpg');
  }

  .business-entry {

  }
}

@media (max-width: 959px) {
  .business-directory {

    .business-entry {
      h3 {
        margin-bottom: 5px;
      }

      .name {
        margin-bottom: 10px;
      }

    }
  }
}

@media (max-width: 639px) {
  .business-directory {

    .business-entry {
      text-align: center;
    }
  }
}