.navbar-beige {
  background: $beige;

  .uk-navbar-nav > li > a {
    color: $light-bluegray;
    font-weight: 500;
    transition: 0.3s ease all;


    &:after {
      content: "•";
      display: inline-block;
      margin-left: 30px;
      color: $gold;
    }

    &.uk-active {
      color: $gold;
    }

    &:hover {
      color: $gold;
    }

  }

  .uk-navbar-nav > li:last-child > a:after {
    display: none;
  }
}

.hamburger {
  height: 5px;
  background: #000;
  width: 35px;
  position: relative;
  margin: 5px;
}

.hamburger:before {
  content: "";
  height: 5px;
  background: #000;
  width: 35px;
  display: block;
  position: absolute;
  top: -10px;
}

.hamburger:after {
  content: "";
  height: 5px;
  background: #000;
  width: 35px;
  display: block;
  position: absolute;
  top: 10px;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: $beige;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  margin-bottom: 10px;
  a, span {
    color: #40454a;
  }
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.uk-navbar-nav > li > a:focus {
  color: inherit;
}