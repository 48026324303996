@import '../variables';

.sidebar {
  .uk-list {
    span {
      > a {
        color: $gold;
      }
    }
  }
}