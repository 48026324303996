.uk-logo {
  img {
    filter: brightness(0%);
    padding-bottom: 5px;
  }
}

.uk-navbar-item {
  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}

.uk-navbar-nav > li > a {
  color: #000;
  font-weight: 700;
}

header {

  .top-banner {
    background: $dark-bluegray;
  }

  .editions {
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        color: $gold;
        padding: 0 5px 0 0;

        &:before {
          content: "·";
          display: inline-block;
          margin-right: 5px;
        }

        &:first-child:before {
          display: none;
        }
      }
    }
  }

  .uk-navbar {

    .button-accept {
      background: #803b8e;
      color: #ffffff;
      text-transform: inherit;
      font-weight: 600;
      padding: 0;
      line-height: 1.5rem;
    }

    .inner-border {
      border: 1px solid rgba(255, 255, 255, 0.4);
      margin: 5px 7px;
      padding: 3px 12px;
      white-space: nowrap;
    }

    .uk-button {
      max-height: 42px;
    }

  }

  .nav-mobile {
    img {
      max-width: 220px;
    }
  }

}

.uk-navbar-nav {
  > li {
    > a {
      height: 80px;
    }
  }

}


@media (max-width: 959px) {
  header {

    .nav-item {
      display: block;
      margin-bottom: 10px;
    }

  }
}