footer {
  color: $footer-color;


  a {
    color: $footer-color;
  }

  a:hover {
    color: $gold;
  }

  h3 {
    color: $footer-color;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.2rem;
  }

  .logo {
    img {
      filter: invert(50%);
      display: block;
      margin: 0 auto;
      margin-bottom: 23px !important;
      max-width: 90%;
    }
  }

  .uk-text-small {
    color:  $footer-border-color;
  }

  .footer-menu {
    list-style-type: none;
    padding: 0;

    li {

      margin-bottom: 7px;

      &:before {
        content: "→";
        color: $footer-border-color;
        margin-right: 5px;

      }
    }
  }

  .uk-border-circle {
    min-width: 50px;
    max-width: 50px;
    min-height: 50px;
    max-height: 50px;
    border: 2px solid $footer-border-color;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 11px;
    margin-right: 15px;
  }

  .contact {
    margin: 40px 0;

    h3 {
      margin-bottom: 5px;
    }
  }

  .main-footer {
    padding: 50px 0 40px 0;
    background: $footer-bg;
    text-align: center;
  }

  .sub-footer {
    padding: 50px 0 40px 0;
    background: $footer-sub-bg;
  }


}