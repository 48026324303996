@import "../variables";

.rte {
  text-align: left;
  border-radius: 0 !important;
  border-color: $light-gold !important;
  background-color: $beige !important;

  /** Toolbar */
  > div:nth-child(1) {
    background: $light-gold !important;
    margin: 0 !important;
    padding-left: 10px !important;
    padding-right: 10px !important;

    button, select {
      border-radius: 0 !important;
    }

    button {
      background: $white !important;
    }

    select + span {
      border-radius: 0 !important;
      background: #fff;
    }
  }

  /** Editor */
  > div:nth-child(2) {
    padding: 40px !important;
    font-family: "Open Sans", sans-serif;
  }

  .DraftEditor-editorContainer {
    > div {

    }
  }


}

