/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/open-sans-v34-latin/open-sans-v34-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../assets/fonts/open-sans-v34-latin/open-sans-v34-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/open-sans-v34-latin/open-sans-v34-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/open-sans-v34-latin/open-sans-v34-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/open-sans-v34-latin/open-sans-v34-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/open-sans-v34-latin/open-sans-v34-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* playfair-display-regular - latin */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/playfair-display-v30-latin/playfair-display-v30-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../assets/fonts/playfair-display-v30-latin/playfair-display-v30-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/playfair-display-v30-latin/playfair-display-v30-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/playfair-display-v30-latin/playfair-display-v30-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/playfair-display-v30-latin/playfair-display-v30-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/playfair-display-v30-latin/playfair-display-v30-latin-regular.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
}
/* catamaran-regular - latin */
@font-face {
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/catamaran-v17-latin/catamaran-v17-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../assets/fonts/catamaran-v17-latin/catamaran-v17-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/catamaran-v17-latin/catamaran-v17-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/catamaran-v17-latin/catamaran-v17-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/catamaran-v17-latin/catamaran-v17-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/catamaran-v17-latin/catamaran-v17-latin-regular.svg#Catamaran') format('svg'); /* Legacy iOS */
}
