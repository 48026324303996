@import "../variables";

.home {

  .special-button {
    max-height: 42px;
    background: $hightlight-color;
    color: #ffffff;
    text-transform: inherit;
    font-weight: 600;
    padding: 0;
    line-height: 1.5rem;

    .inner-border {
      border: 1px solid rgba(255, 255, 255, 0.4);
      margin: 5px 7px;
      padding: 3px 12px;
      white-space: nowrap;
    }
  }

  .banner-search {
    overflow: hidden;
    background: url('../assets/images/rose_wood.jpg') no-repeat top center;
    background-size: cover;


    .uk-container {
      background: rgba(255, 255, 255, 0.5);
      max-width: 600px;
    }

    a {
      color: #fff;
      text-decoration: none;
    }

    h2 {
      font-size: 1.6rem;
      text-align: center;
    }

    .input-calendar {
      color: #9d9d9b;

      .icon-wrapper {
        transform: translateX(-35px);
        border-left: 1px solid #9d9d9b;
        margin: 6px 0;
        padding: 2px 0 0 10px;
      }

    }
  }

  .current-death-notices {
    background: $beige;

    h2 {
      text-align: center;
      margin: 0 0 10px 0;
      letter-spacing: 3px;
      text-transform: uppercase;
      font-size: 1.3rem;
    }

    .death-notice {
      background: $dark-bluegray;
      overflow: hidden;
      cursor: pointer;
      height: 100%;

      h3 {
        color: $white;
        font-size: 2.2rem;
        margin: 20px 0 5px;
      }

      .date {
        font-family: $default-font-serif;
      }

      figure {
        background: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, .2);
        overflow: hidden;
        margin-bottom: 10px;
        height: 100%;
        padding: 10px;
      }

    }

  }

  .current-stuff {
    // background: $beige;
    background: $white;


    h2 {
      text-align: center;
      margin: 0 0 10px 0;
      letter-spacing: 3px;
      text-transform: uppercase;
      font-size: 1.3rem;
    }

    .condolences {
      .uk-list {
        span {
          > a {
            color: $text-color;
          }
        }
      }
    }

    .commemorations {
      .uk-list {
        height: 360px;
      }

    }
  }

  .suggestion-list {
    position: absolute;
    z-index: 1;
    margin: 0;
    padding: 0;
    list-style-type: none;
    border-left: 1px solid $light-gold;
    border-right: 1px solid $light-gold;
    border-bottom: 1px solid $light-gold;
    background: $beige;

    li {
      padding: 5px 15px;
      border-bottom: 1px solid rgba($light-gold, 0.75);

      &:hover {
        background: rgba($gold, 0.5);
        cursor: pointer;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  .date-search-padding {
    padding-left: 120px;
  }
}


@media (min-width: 640px) {

}