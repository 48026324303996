@import "../variables";

.death-notice-overview {
  .current-death-notices {
    background: $beige;

    h2 {
      text-align: center;
      margin: 0 0 10px 0;
      letter-spacing: 3px;
      text-transform: uppercase;
      font-size: 1.3rem;
    }

    .death-notice {
      background: $dark-bluegray;
      overflow: hidden;
      cursor: pointer;
      height: 100%;

      h3 {
        color: $white;
        font-size: 2.2rem;
        margin: 20px 0 5px;
      }

      .date {
        font-family: $default-font-serif;
      }

      figure {
        background: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, .2);
        overflow: hidden;
        margin-bottom: 10px;
        height: 100%;
        padding: 10px;
      }

    }

  }

    .buttonContainer {
      display: flex;
      width: 100%;
      @media (min-device-width: 350px) and (max-device-width: 500px) {
        margin-top: 64px;
      .latestContainer {
        position: absolute;
        margin-top: -64px;
        width: calc(100% - 60px);

        #todayButton {
          width: 100%;
        }
      }
    }
      @media (max-device-width: 350px) {
        flex-direction: column;
        align-items: center;
        button {
          width: max-content;
        }
        input {
          margin-top: 16px;
          margin-bottom: 16px;
        }
      }
  }
}
