@import "../variables";

.condolence {
  .user-buttons {
    margin-top: -20px;
    margin-bottom: 10px;

    > svg {
      cursor: pointer;
    }
  }
}

.uk-button-unsubscribe {
  background: $dark-bluegray;
  color: $white;

  &:hover {
    background: lighten($dark-bluegray, 10%);
  }
}

.uk-button-subscribe {
  background: $gold;
  color: $white;

  &:hover {
    background: darken($gold, 5%);
  }
}

.report-button {
  background: rgba($white, 0.4);
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 5px 10px;
  cursor: pointer;
}

.displayed-public {
  position: absolute;
  top: 14px;
  right: 20px;
  color: #3e3b3b;
  user-select: none;
  font-size: 0.6rem;
}