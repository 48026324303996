@import "../variables";

.tooltip {
  position: absolute;
  top: -35px;
  background: #f9e9e9;
  border: 1px solid $darker-gray;
  padding: 5px 10px;
  font-size: 0.8rem;

  &:after, &:before {
    top: 100%;
    left: 20%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(255, 250, 250, 0);
    border-top-color: #f9e9e9;
    border-width: 10px;
    margin-left: -10px;
  }

  &:before {
    border-color: rgba(74, 74, 72, 0);
    border-top-color: #4a4a48;
    border-width: 11px;
    margin-left: -11px;
  }
}

.uk-tooltip {
  &.uk-active {
    padding: 10px;
    min-width: 300px;
    margin-top: 80px;
    ul {
      margin: 0 0 5px 0;
      padding: 5px 0 0 20px;

    }
  }

}