// Font
$default-font-sans-serif: 'Open Sans', sans-serif;
$default-font-serif: 'Playfair Display', serif;
$quote-font: 'Catamaran', sans-serif;

// Colors
$hightlight-color: #bca34e;
$hightlight-color-active: #bca143;
$light-gray: #c1c1c1;
$shaded-gray: #9c9c9a;
$default-gray: #e3e3e3;
$darker-gray: #4a4a48;
$dark-bluegray: #1c242c;
$light-bluegray: #40454a;
$green: green;
$red:  darkred;
$white: #fff;
$beige: #f7f4eb;
$gold: #bca34e;
$light-gold: #eae1c5;

$text-color: #333 !default;


.uk-color-green { color: $green !important; }
.uk-color-red { color: $red !important; }
.uk-color-white { color: $white !important; }
.uk-color-gold { color: $gold !important; }

// Cookie
$cookie-bg: $hightlight-color;
$cookie-color: #fffffd;

// Footer
$footer-bg: #000000;
$footer-color: #9c9c9a;
$footer-border-color: #575757;
$footer-sub-bg: #101010;

// Button
$btn-default-border: #999898;

// redefine uikit
$global-font-family: 'Open Sans' !default;
$global-color: $text-color !default;
$global-link-color: $hightlight-color !default;
$global-primary-background: $hightlight-color !default;
$form-focus-border: $hightlight-color !important;
$navbar-dropdown-background: $dark-bluegray;
$navbar-dropdown-color: $white;
$navbar-dropdown-nav-item-hover-color: $gold;
$navbar-dropdown-nav-divider-border: #999;
