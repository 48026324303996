.cookie-message {
  background: $cookie-bg;
  color: $cookie-color;

  a {
    color: $cookie-color;
    text-decoration: underline;
  }

  .button-accept {
    background: #fff;
    color: #803b8e;
    text-transform: inherit;
    font-weight: 600;
    padding: 0;
    line-height: 1.5rem;
  }

  .inner-border {
    border: 1px solid rgba(128, 59, 142, 0.2);
    margin: 5px;
    padding: 2px 10px;
  }

  .uk-button-default {
    color: #fff;
  }
}

