// Breakpoints
@import "~uikit/src/scss/variables";
@import "../variables";


@media (min-width: $breakpoint-small) {


}

/* Tablet landscape and bigger */
@media (min-width: $breakpoint-medium) {


}

/* Desktop and bigger */
@media (min-width: $breakpoint-large) {


}

/* Large screen and bigger */
@media (min-width: $breakpoint-xlarge) {


}


.uk-text-dark {
  color: $text-color;
}

.uk-pull-right {
  float: right;
}

.uk-pull-right:after {
  content: "";
  display: block;
  clear: both;
}



